import { useState } from "react";
import TableHeader from "components/table-header";
import { PencilIcon } from "@heroicons/react/24/outline";

export default function Table({
    tableTitle = "",
    tableHeaders = [],
    tableData = [],
    editable = false,
    setEditableRow = {},
    fontSize = "text-sm",
}) {
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [filterByColumnHeaders, setFilterByColumnHeaders] =
        useState(tableHeaders);

    const filterByColumn = (column, checked) => {
        let headers = tableHeaders.map((header) => {
            if (header.key === column) {
                if (checked) {
                    header.showOnTable = true;
                    header.showOnPrint = true;
                } else {
                    header.showOnTable = false;
                    header.showOnPrint = false;
                }
            }
            return header;
        });
        setFilterByColumnHeaders(headers);
    };

    return (
        <>
            <TableHeader
                tableTitle={tableTitle}
                tableHeaders={filterByColumnHeaders}
                tableData={tableData}
                filterString={filterString}
                setFilterString={setFilterString}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
            />

            <div className="mx-full px-4 sm:px-6 lg:px-8">
                {filterByColumnHeaders.map((header, i) => (
                    <label
                        htmlFor={header.key}
                        key={i}
                        className="header-tag-green m-1"
                    >
                        <input
                            type="checkbox"
                            className="mr-1"
                            id={header.key}
                            name={"filter"}
                            value={header.value}
                            onChange={(event) =>
                                filterByColumn(header.key, event.target.checked)
                            }
                            checked={header.showOnTable}
                        />{" "}
                        {header.value}
                    </label>
                ))}
            </div>

            <div className="mx-full px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    {tableHeaders
                                        .filter(
                                            (header) =>
                                                header.showOnTable === true
                                        )
                                        .map((header, i) => (
                                            <th
                                                key={i}
                                                className="px-6 py-3 bg-gray-50 text-left font-medium text-sm text-gray-500"
                                            >
                                                {header.value}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody className="min-w-full bg-white divide-y divide-gray-200">
                                {(filterString.length || filteredData.length
                                    ? filteredData
                                    : tableData
                                ).map((row) => (
                                    <tr key={row.id} className="bg-white">
                                        {tableHeaders
                                            .filter(
                                                (header) =>
                                                    header.showOnTable === true
                                            )
                                            .map((column, i) => (
                                                <td
                                                    key={i}
                                                    className={`px-6 py-4 text-gray-500 ${fontSize}`}
                                                >
                                                    {Array.isArray(
                                                        row.normalised[
                                                            column.key
                                                        ]
                                                    )
                                                        ? row.normalised[
                                                              column.key
                                                          ].map((val, i) => (
                                                              <div
                                                                  key={i}
                                                                  className="mb-1"
                                                              >
                                                                  {Array.isArray(
                                                                      val
                                                                  )
                                                                      ? val.join(
                                                                            ", "
                                                                        )
                                                                      : val}
                                                              </div>
                                                          ))
                                                        : row.normalised[
                                                              column.key
                                                          ]}

                                                    {i === 0 &&
                                                        editable &&
                                                        !row?.closed && (
                                                            <button
                                                                type="button"
                                                                className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                            >
                                                                <PencilIcon
                                                                    className="h-4 w-4 mr-1"
                                                                    aria-hidden="true"
                                                                    onClick={() =>
                                                                        setEditableRow(
                                                                            row
                                                                        )
                                                                    }
                                                                />
                                                            </button>
                                                        )}
                                                </td>
                                            ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Pagination */}
                        <nav
                            className="w-full bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                            aria-label="Pagination"
                        >
                            <div className="hidden sm:block">
                                <p className="text-sm text-gray-700">
                                    Showing{" "}
                                    <span className="font-medium">
                                        {filterString.length ||
                                        filteredData.length
                                            ? filteredData.length
                                            : tableData.length}
                                    </span>{" "}
                                    of{" "}
                                    <span className="font-medium">
                                        {tableData.length}
                                    </span>{" "}
                                    results
                                </p>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}
