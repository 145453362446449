import { useEffect, useState, useContext } from "react";
import { NavLink, Navigate, useMatch, useLocation } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import PageHeader from "components/page-header";
import TableKPI from "components/table-kpi";
import Modal from "components/modal";
import Delete from "components/delete";
import AddEditKpa from "components/forms/add-edit-kpa";
import Alert from "components/alert";
import { useTextFormatHook } from "hooks/text-formatter";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import { PORTAL } from "routes/portal";
import { APP_ID } from "config";
import { GLOBAL } from "constants/global";
import { ActiveKpaContext } from "contexts/active-kpa";
import * as Sentry from "@sentry/react";
import { AppLoaderContext } from "contexts/app-loader";
import { format } from "date-fns";
import { getYear } from "date-fns";
import { useTermsHook } from "hooks/terms";

export default function KPIs() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    let { setLoading } = useContext(AppLoaderContext);

    /* KPIs by KPA and Term */
    const kpisByKpa = useMatch(
        "/:type" + PORTAL.KPIS_BY_KPA + "/:kpa_id/:term_id/:year"
    );
    let type = kpisByKpa?.params?.type;
    let kpaId = kpisByKpa?.params?.kpa_id;
    let termId = kpisByKpa?.params?.term_id;
    let year = kpisByKpa?.params?.year;

    let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID));
    let { normaliseTableData } = useTextFormatHook();
    let { getTermIndexByYear } = useTermsHook();
    const [kpis, setKpis] = useState([]);
    const [kpa, setKpa] = useState([]);
    const [term, setTerm] = useState([]);
    const [yearIndex, setYearIndex] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditKpa, setOpenAddEditKpa] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    let { setActiveKpa } = useContext(ActiveKpaContext);

    const pageHeaderButtons = [
        type === "sdbip" ? (
            <NavLink
                to={`${PORTAL.IDP_KPIS_BY_KPA}/${kpa?.id}/${term?.id}/${year}`}
                className={`button-default`}
                onClick={() => {
                    setActiveKpa({});
                }}
            >
                <span className="button-icon">
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                Back to Corporate Plan KPIs
            </NavLink>
        ) : (
            <NavLink
                to={`${PORTAL.KPAS_BY_TERM + `/${term?.id}`}`}
                className={`button-default`}
                onClick={() => {
                    setActiveKpa({});
                }}
            >
                <span className="button-icon">
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                Back to Corporate Plan KPAs
            </NavLink>
        ),
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "KPA",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "ndp_chapters",
            value: "NDP Chapter(s)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "outcome",
            value: "Vision",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "back_to_basics",
            value: "Mission",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "provincial_pillars",
            value: "Values",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "regional_strategic_goals",
            value: "Slogan",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "regional_outcome",
            value: "Regional Outcome",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "goals",
            value: "Strategic Goal(s)/Objective(s)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = <>{row.normalised.name} </>;
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.CONTENT.KPA_DETAIL(kpaId)).then((res) => {
            let normalisedData = normaliseTableData(tableHeaders, [res.data]);
            setKpa(addLinksToTableData(normalisedData)[0]);
            setTerm(res.data.term);
            setYearIndex(getTermIndexByYear(res.data.term, year));
        });

        ApiConsumer.get(API_ROUTES.CONTENT.KPI_BY_KPA(kpaId))
            .then((res) => {
                console.log(res.data);
                setKpis(res.data);
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setKpis({});
            setKpa({});
            setTerm({});
            setLoaded(false);
            setOpenAddEditKpa(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, [kpaId, termId]);

    const onCloseAddEditKpa = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditKpa(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditKpa(false)
            : setOpenAddEditKpa(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
        return <Navigate to={PORTAL.LOGIN} />;

    // if (kpaId === undefined || termId === undefined || type === undefined)
    //     return <Navigate to={PORTAL.TERMS} />;
    //
    // if (term.length !== 0 && !yearIndex) return <Navigate to={PORTAL.TERMS} />;

    return (
        <>
            {loaded && (
                <>
                    {term.length !== 0 && kpis.length ? (
                        <PageHeader
                            pageHeaderName={
                                type.toUpperCase().replace("SDBIP", "APP").replace("IDP", "Corporate Plan") +
                                ` for: ` +
                                kpa?.name
                                    .toUpperCase()
                                    .replace("SDBIP", "APP") +
                                `<br /><span class="header-tag-red">TERM: ` +
                                format(
                                    new Date(term?.year1_start_date),
                                    "dd MMM yyyy"
                                ) +
                                ` to ` +
                                format(
                                    new Date(term?.year5_end_date),
                                    "dd MMM yyyy"
                                ) +
                                `</span><span class="header-tag-red">APP FOR YEAR ${year}</span>`
                            }
                            pageHeaderButtons={pageHeaderButtons}
                        />
                    ) : null}

                    {kpis.length ? (
                        <TableKPI
                            type={type}
                            year={year}
                            yearIndex={yearIndex}
                            kpi={kpis[0]}
                            tableTitle={`APP`}
                            tableHeaders={tableHeaders}
                            tableData={[kpa]}
                            editable={true}
                            setEditableRow={setEditableRow}
                            editableRow={editableRow}
                            loadData={loadData}
                        />
                    ) : (
                        <div className="mx-full px-8 my-4 text-center">
                            <Alert
                                type={`danger`}
                                message={
                                    `There are no KPIs (indicators) added under ` +
                                    kpa?.name.replace("SDBIP", "APP")
                                }
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
}
