import { useEffect, useState, useContext } from "react";
import { PORTAL } from "routes/portal";
import { NavLink, useMatch, useLocation } from "react-router-dom";
import { ChevronLeftIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useTextFormatHook } from "hooks/text-formatter";
import { format } from "date-fns";
import PageHeader from "components/page-header";
import AddEditTask from "components/forms/add-edit-task";
import TaskActivity from "components/task-activity";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import Alert from "components/alert";
import ViewUserDetails from "components/view-user-details";
import Attachments from "components/attachments";
import AddEditProject from "components/forms/add-edit-project";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import Status from "components/progress-slider/status";
import Progress from "components/progress-slider/progress";
import Bar from "components/progress-slider/bar";
import BarQuarters from "components/progress-slider/bar-quarters";
import DownloadAsPdf from "components/share/download-pdf";
import { GLOBAL } from "constants/global";
import { useAuthentication } from "hooks/authentication";
import { PERMISSIONS } from "constants/permissions";
import { AppLoader } from "components/loader/app-loader";
import { AppLoaderContext } from "contexts/app-loader";
import SubTasks from "components/sub-tasks";
import { useDataLoaderHook } from "hooks/data-loader";
import { useTermsHook } from "hooks/terms";
import Comments from "components/comments";

export default function Task() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    const taskId = useMatch(PORTAL.TASK + "/:task_id").params.task_id;

    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    let { hasPermission } = useAuthentication();
    let { loadDropdown } = useDataLoaderHook();
    let { getTermIndexByYear } = useTermsHook();

    const [maxValues, setMaxValues] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [project, setProject] = useState({});
    const [task, setTask] = useState({});

    const [yearIndex, setYearIndex] = useState(0);

    const [progressSubmitted, setProgressSubmitted] = useState(false);
    const [progressDetails, setProgressDetails] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [openAddEditTask, setOpenAddEditTask] = useState(false);
    const [openAddEditProject, setOpenAddEditProject] = useState(false);

    const [editableRow, setEditableRow] = useState({});
    const [editableProject, setEditableProject] = useState({});

    const [rowUpdated, setRowUpdated] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [projectActivities, setProjectActivities] = useState([]);

    const numberFormatter = new Intl.NumberFormat("en-US");
    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2,
    });

    const pageHeaderButtons = [];

    const pageHeaderIcons = [
        !project.closed &&
            hasPermission(PERMISSIONS.PROJECTS.CAN_EDIT_PROJECT) && (
                <button
                    type="button"
                    className="icon-transparent bg-gray-100 ml-3"
                >
                    <PencilIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        onClick={() => {
                            setEditableProject(project);
                            setOpenAddEditProject(true);
                        }}
                    />
                </button>
            ),
        <DownloadAsPdf
            tableTitle={`Project:`}
            tableHeaders={GLOBAL.PROJECT_HEADERS}
            tableData={[project]}
            tableDataResult={project?.name}
            printType={GLOBAL.PRINT.PAGE}
            filename={`Project: ${project?.name}`}
        />,
    ];

    const subHeaderButtons = [
        <NavLink
            to={`${PORTAL.TASKS_BY_PROJECT}/${task?.project?.id}`}
            className={`button-default`}
        >
            <span className="button-icon">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Back to Task List
        </NavLink>,
    ];

    const subHeaderIcons = [
        !task.closed && hasPermission(PERMISSIONS.TASKS.CAN_EDIT_TASK) && (
            <button type="button" className="icon-transparent bg-gray-100 ml-3">
                <PencilIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                    onClick={() => setEditableRow(task)}
                />
            </button>
        ),
        <DownloadAsPdf
            tableTitle={`Task: `}
            tableHeaders={GLOBAL.TASK_HEADERS}
            tableData={[task]}
            tableDataResult={`${task?.name}`}
            printType={GLOBAL.PRINT.PAGE}
            filename={`Task: ${task?.name}`}
        />,
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.TASK}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const loadData = () => {

        setLoaded(false);

        loadDropdown(API_ROUTES.PROJECT.ACTIVITY, setProjectActivities);

        ApiConsumer.get(API_ROUTES.TASKS.TASK_DETAIL(taskId))
            .then((res) => {
                let normalisedData = normaliseTableData(GLOBAL.TASK_HEADERS, [
                    res.data,
                ]);
                setTask(addLinksToTableData(normalisedData)[0]);

                setYearIndex(
                    getTermIndexByYear(
                        res.data.project.kpa.term,
                        res.data.project.year
                    )
                );

                setProject(
                    normaliseTableData(GLOBAL.PROJECT_HEADERS, [
                        res.data.project,
                    ])[0]
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setSidebarOpen(false);
            setProject({});
            setTask({});
            setLoaded(false);
            setOpenAddEditTask(false);
            setOpenAddEditProject(false);

            setEditableRow({});
            setEditableProject({});

            setRowUpdated(false);
        };
    }, [taskId]);

    const onCloseAddEditTask = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditTask(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditTask(false)
            : setOpenAddEditTask(true);
    }, [editableRow]);

    const onCloseAddEditProject = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableProject({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditProject(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableProject).length === 0
            ? setOpenAddEditProject(false)
            : setOpenAddEditProject(true);
    }, [editableProject]);

    const calculateProgress = function () {
        let annualProgress =
            parseInt(progressDetails.quarter1 ? progressDetails.quarter1 : 0) +
            parseInt(progressDetails.quarter2 ? progressDetails.quarter2 : 0) +
            parseInt(progressDetails.quarter3 ? progressDetails.quarter3 : 0) +
            parseInt(progressDetails.quarter4 ? progressDetails.quarter4 : 0);
        return annualProgress;
    };

    return (
        <>
            <Modal
                modalHeaderTag={
                    project?.kpa?.name !== undefined
                        ? `SECTION: ` + project.name
                        : editableProject?.project?.name !== undefined
                        ? `SECTION: ` + editableProject.programme.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableProject).length === 0
                        ? `Add New Project`
                        : `Edit Project: ${editableProject.name}`
                }
                modalContent={
                    <AddEditProject
                        year={
                            editableRow?.year !== undefined
                                ? editableRow.year
                                : project?.year
                        }
                        kpaId={
                            editableProject?.kpa?.id !== undefined
                                ? editableProject.kpa.id
                                : project?.kpa?.id
                        }
                        kpi={project?.indicator}
                        setOpen={onCloseAddEditProject}
                        editableRow={editableProject}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditProject}
                setOpen={onCloseAddEditProject}
            />

            <Modal
                modalHeaderTag={
                    project?.programme?.name !== undefined
                        ? `PROJECT: ` + project.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Task`
                        : `Edit Task: ${editableRow.name}`
                }
                modalContent={
                    <AddEditTask
                        project={project}
                        setOpen={onCloseAddEditTask}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditTask}
                setOpen={onCloseAddEditTask}
            />

            <PageHeader
                pageHeaderName={`${
                    project?.name !== undefined
                        ? `Project: ` + project.name
                        : ""
                } 
              <span class="header-tag-red">
                ${project?.year !== undefined ? project.year : ""}
              </span>
              <span class="header-tag-red">
                ${project?.kpa?.name !== undefined ? project.kpa.name : ""}
              </span>`}
                pageHeaderButtons={pageHeaderButtons}
                pageHeaderIcons={pageHeaderIcons}
            />

            <PageHeader
                pageHeaderName={`${
                    task?.name !== undefined ? `Task: ` + task.name : ""
                }`}
                pageHeaderButtons={subHeaderButtons}
                pageHeaderIcons={subHeaderIcons}
            />

            {hasPermission(PERMISSIONS.TASKS.CAN_VIEW_TASK) ? (
                loaded && (
                    <>
                        <Modal
                            modalHeaderTag={""}
                            modalHeader={`User Details: ${task.created_by.first_name} ${task.created_by.last_name}`}
                            modalContent={
                                <ModalUserDetails
                                    setOpen={setOpenUserDetails}
                                    userDetailsRow={userDetailsRow}
                                />
                            }
                            open={openUserDetails}
                            setOpen={setOpenUserDetails}
                        />
                        <div className="mt-8 grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                <section>
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <h2
                                                    id="applicant-information-title"
                                                    className="flex-1 text-lg font-medium text-gray-900"
                                                >
                                                    <strong>KPI</strong>
                                                </h2>
                                            </div>
                                            <p className="mt-1 max-w-2xl  text-gray-500">
                                                {task.kpi.indicator_name}
                                            </p>
                                        </div>
                                        <div className="border-t border-gray-200 p-5">
                                            <div className="flex items-center mb-2">
                                                <h2
                                                    id="applicant-information-title"
                                                    className="flex-1 text-lg font-medium text-gray-900"
                                                >
                                                    <strong>Task Details</strong>
                                                </h2>
                                            </div>
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Task Date &amp;
                                                        Duration:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {
                                                            task.normalised
                                                                .start_date
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            task.normalised
                                                                .end_date
                                                        }{" "}
                                                        (
                                                        {
                                                            task.normalised
                                                                .duration
                                                        }
                                                        )
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Actual expenditure (R):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {currencyFormatter
                                                            .format(
                                                                task.expenditure
                                                            )
                                                            .replace(
                                                                /[ZA\s]/g,
                                                                ""
                                                            )}
                                                    </dd>
                                                </div>

                                            </dl>
                                        </div>


                                        <div className="border-t border-gray-200 p-5">
                                            <div className="flex items-center mb-2">
                                                <h2
                                                    id="applicant-information-title"
                                                    className="flex-1 text-lg font-medium text-gray-900"
                                                >
                                                    <strong>Project Details</strong>
                                                </h2>
                                            </div>
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                                

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Corporate Plan Ref No:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project.idp_ref}
                                                    </dd>
                                                </div>
                                               

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Draft Budget (R):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {currencyFormatter
                                                            .format(
                                                                project.budget
                                                            )
                                                            .replace(
                                                                /[ZA\s]/g,
                                                                ""
                                                            )}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        2nd Draft Budget (R):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {currencyFormatter
                                                            .format(
                                                                project.second_budget
                                                            )
                                                            .replace(
                                                                /[ZA\s]/g,
                                                                ""
                                                            )}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Final Budget (R):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {currencyFormatter
                                                            .format(
                                                                project.actual_budget
                                                            )
                                                            .replace(
                                                                /[ZA\s]/g,
                                                                ""
                                                            )}
                                                    </dd>
                                                </div>

                                                

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Score Type:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {
                                                            project.indicator
                                                                .indicator_umo
                                                                .name
                                                        }
                                                    </dd>
                                                </div>
                                                {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                        `PERCENTAGE` ||
                                    project?.indicator.indicator_umo?.name.toUpperCase() ===
                                        `NUMBER` ? <>

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Baseline (Previous
                                                        Year):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {
                                                            project.indicator
                                                                .baseline
                                                        }
                                                        {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                        `PERCENTAGE`
                                                            ? `%`
                                                            : ""}
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Annual Target (Current
                                                        Year):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {
                                                            project?.indicator[
                                                                `indicator_year` +
                                                                    yearIndex
                                                            ]
                                                        }
                                                        {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                        `PERCENTAGE`
                                                            ? `%`
                                                            : ""}
                                                    </dd>
                                                </div>
                                                </>: null}
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Ward:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project?.wardnum}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        GPS Coordinates (Long):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project?.longitude}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        GPS Coordinates (Lat):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project?.latitude}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>

                                        {project?.partners?.length ? (
                                            <div className="border-t border-gray-200 p-5">
                                                <p className="pt-1">
                                                    <strong>Funded By:</strong>{" "}
                                                    {project?.partners?.map(
                                                        (partner, i) => (
                                                            <span
                                                                key={i}
                                                                className="mr-1 rounded-md bg-gray-100 py-1 px-2 text-sm"
                                                            >
                                                                {partner.name}
                                                            </span>
                                                        )
                                                    )}
                                                </p>
                                            </div>
                                        ) : null}

                                        <div className="border-t border-gray-200 p-5">
                                            <ViewUserDetails row={task} />
                                            <p className="pt-1">
                                                <strong>
                                                    Department / Division:
                                                </strong>{" "}
                                                {project?.departments?.map(
                                                    (department, i) => (
                                                        <span
                                                            key={i}
                                                            className="mr-1 rounded-md bg-gray-100 py-1 px-2 text-sm"
                                                        >
                                                            {department.name}
                                                        </span>
                                                    )
                                                )}
                                            </p>
                                        </div>

                                        <div className="border-t border-gray-200 p-5 rounded-bl-lg rounded-br-lg bg-gray-100 text-sm">
                                            <span className="font-bold">
                                                Created:
                                            </span>{" "}
                                            {format(
                                                new Date(task.created),
                                                "dd MMM yyyy"
                                            )}{" "}
                                            by{" "}
                                            <span
                                                className="hover:underline cursor-pointer"
                                                onClick={() => {
                                                    setUserDetailsRow(
                                                        task.created_by
                                                    );
                                                    setOpenUserDetails(true);
                                                }}
                                            >
                                                {task.created_by.first_name +
                                                    " " +
                                                    task.created_by.last_name}
                                            </span>{" "}
                                            <span className="font-bold">
                                                Last updated:
                                            </span>{" "}
                                            {task.normalised.updated}
                                        </div>
                                    </div>
                                </section>

                                <section aria-labelledby="notes-title">
                                    <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                                        <Comments
                                            type={`task`}
                                            route={API_ROUTES.COMMENTS.TASKS}
                                            id={taskId}
                                        />
                                    </div>
                                </section> 
                            </div>

                            <section
                                aria-labelledby="timeline-title"
                                className="lg:col-start-3 lg:col-span-1"
                            >
                                
                                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-4">
                                    <Attachments
                                        type={`task`}
                                        route={API_ROUTES.ATTACHMENTS.TASKS}
                                        id={taskId}
                                    />
                                </div>

                                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                    <TaskActivity task_id={taskId} />
                                </div>
                            </section>
                        </div>
                    </>
                )
            ) : (
                <div className="mx-full px-8 my-4 text-center">
                    <Alert
                        type={`danger`}
                        message={`You do not have permission to view tasks. Please contact the system administrator.`}
                    />
                </div>
            )}
        </>
    );
}
