import {
    CursorArrowRaysIcon,
    BuildingLibraryIcon,
    RectangleGroupIcon,
    UserGroupIcon,
    ChartPieIcon,
    ShieldCheckIcon,
    SquaresPlusIcon,
    PresentationChartBarIcon,
    Cog8ToothIcon,
    PaintBrushIcon,
    ClipboardDocumentListIcon,
    RectangleStackIcon,
    QueueListIcon,
    ListBulletIcon,
    CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { PORTAL } from "routes/portal";

export const PRIMARY_NAVIGATION = [
    {
        name: "Main Dashboard",
        href: "/",
        icon: BuildingLibraryIcon,
    },
];

export const IDP_NAVIGATION = [
    {
        name: "Corporate Plans",
        href: `${PORTAL.TERMS}`,
        icon: CalendarDaysIcon,
    },
    {
        name: "Corporate Plan Key Performance Areas",
        href: `${
            PORTAL.KPAS_BY_TERM + `/b57a0e6e-d21f-4c23-8185-11596a9fd56c`
        }`,
        icon: ChartPieIcon,
        permission: "view_kpa",
    },
    /* {
        name: "IDP Projects",
        href: `${
            PORTAL.KPAS_BY_TERM + `/b57a0e6e-d21f-4c23-8185-11596a9fd56c`
        }`,
        icon: QueueListIcon,
        permission: "view_kpa",
    }, */
];

export const REPORTING_NAVIGATION = [
    {
        name: "Annual Performance Reports",
        href: `${PORTAL.REPORTS}`,
        icon: PresentationChartBarIcon,
        permission: "view_overallreports",
    },
   
    {
        name: "Reports by Projects",
        href: `${PORTAL.PROJECT_REPORTS}`,
        icon: QueueListIcon,
        permission: "view_projectreports",
    },
    {
        name: "Reports by Task",
        href: `${PORTAL.TASK_REPORTS}`,
        icon: ListBulletIcon,
        permission: "view_taskreports",
    },
];

export const SDBI_NAVIGATION = [
    /* {
        name: "Project Activity",
        href: `${PORTAL.PROJECTS}/2024/`,
        icon: QueueListIcon,
        permission: "view_kpa",
    }, */
];

export const ADMIN_NAVIGATION = [
    {
        name: "Sections / Grouping",
        href: `${PORTAL.SECTIONS}`,
        icon: RectangleStackIcon,
        permission: "view_department",
    },
    {
        name: "Departments",
        href: `${PORTAL.DEPARTMENTS}`,
        icon: RectangleGroupIcon,
        permission: "view_department",
    },
    {
        name: "System Meta",
        href: `${PORTAL.META}`,
        icon: SquaresPlusIcon,
        permission: "view_meta",
    },
    // {
    //     name: "System Activity",
    //     href: `${PORTAL.ACTIVITY}`,
    //     icon: CursorArrowRaysIcon,
    //     permission: "view_useractivity",
    // },
    {
        name: "System Users / Staff",
        href: `${PORTAL.USERS}`,
        icon: UserGroupIcon,
        permission: "view_customuser",
    },
    {
        name: "System Administrators",
        href: `${PORTAL.USERS}/administrators`,
        icon: ShieldCheckIcon,
        permission: "view_administrator",
    },
];

export const SETTINGS_NAVIGATION = [
    {
        name: "General",
        href: `${PORTAL.SETTINGS}`,
        icon: Cog8ToothIcon,
        permission: "view_customuser",
    },
    {
        name: "Theme & Brand",
        href: `${PORTAL.THEME}/administrators`,
        icon: PaintBrushIcon,
        permission: "view_administrator",
    },
    {
        name: "Compliance Questionnaires",
        href: `${PORTAL.COMPLIANCE}`,
        icon: ClipboardDocumentListIcon,
        permission: "view_department",
    },
];
