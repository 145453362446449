import { useEffect, useState, useContext } from "react";
import { NavLink, Navigate, useMatch, useLocation } from "react-router-dom";
import { PlusCircleIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import PageHeader from "components/page-header";
import TableKPA from "components/table-kpa";
import Modal from "components/modal";
import Delete from "components/delete";
import AddEditKpa from "components/forms/add-edit-kpa";
import { useTextFormatHook } from "hooks/text-formatter";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import { PORTAL } from "routes/portal";
import { APP_ID } from "config";
import { GLOBAL } from "constants/global";
import { ActiveKpaContext } from "contexts/active-kpa";
import * as Sentry from "@sentry/react";
import { AppLoaderContext } from "contexts/app-loader";
import { format } from "date-fns";

export default function KPAs() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    let { setLoading } = useContext(AppLoaderContext);

    /* KPAs by Term */
    const kpasByTerm = useMatch(PORTAL.KPAS_BY_TERM + "/:term_id");
    let termId = kpasByTerm?.params?.term_id;

    let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID));
    let { normaliseTableData } = useTextFormatHook();
    const [kpas, setKpas] = useState([]);
    const [term, setTerm] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditKpa, setOpenAddEditKpa] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    let { setActiveKpa } = useContext(ActiveKpaContext);

    const pageHeaderButtons = [
        <NavLink to={PORTAL.TERMS} className={`button-default`}>
            <span className="button-icon">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Back to Terms
        </NavLink>,
        <button
            type="button"
            onClick={() => setOpenAddEditKpa(true)}
            className={`button`}
        >
            <span className="button-icon">
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Add KPA
        </button>,
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "KPA",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "ndp_chapters",
            value: "NDP Chapter(s)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "outcome",
            value: "Vision",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "back_to_basics",
            value: "Mission",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "provincial_pillars",
            value: "Values",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "regional_strategic_goals",
            value: "Slogan",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "regional_outcome",
            value: "Regional Outcome",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "goals",
            value: "Strategic Goal(s)/Objective(s)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = <>{row.normalised.name}</>;
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.CONTENT.TERM_DETAIL(termId)).then((res) => {
            setTerm(res.data);
        });

        ApiConsumer.get(API_ROUTES.CONTENT.KPA_BY_TERM(termId))
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setKpas(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setTerm({});
            setKpas({});
            setLoaded(false);
            setOpenAddEditKpa(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    const onCloseAddEditKpa = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditKpa(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditKpa(false)
            : setOpenAddEditKpa(true);
    }, [editableRow]);

    useEffect(() => {}, []);

    if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
        return <Navigate to={PORTAL.LOGIN} />;

    if (termId === undefined) return <Navigate to={PORTAL.TERMS} />;

    return (
        <>
            <Modal
                modalHeaderTag={
                    term.length != 0
                        ? `TERM: ` +
                          format(
                              new Date(term?.year1_start_date),
                              "dd MMM yyyy"
                          ) +
                          ` to ` +
                          format(
                              new Date(term?.year5_end_date),
                              "dd MMM yyyy"
                          ) +
                          ``
                        : ``
                }
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New KPA`
                        : `Edit KPA: ${editableRow.name}`
                }
                modalContent={
                    <AddEditKpa
                        term={term}
                        setOpen={onCloseAddEditKpa}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditKpa}
                setOpen={onCloseAddEditKpa}
            />

            {loaded && (
                <>
                    {" "}
                    {term.length != 0 && (
                        <PageHeader
                            pageHeaderName={
                                `Corporate Plan - Key Performance Areas (${kpas?.length})<br /><span class="header-tag-red">TERM: ` +
                                format(
                                    new Date(term?.year1_start_date),
                                    "dd MMM yyyy"
                                ) +
                                ` to ` +
                                format(
                                    new Date(term?.year5_end_date),
                                    "dd MMM yyyy"
                                ) +
                                `</span>`
                            }
                            pageHeaderButtons={pageHeaderButtons}
                        />
                    )}
                    <TableKPA
                        term={term}
                        tableTitle={`KPAs`}
                        tableHeaders={tableHeaders}
                        tableData={kpas}
                        editable={true}
                        setEditableRow={setEditableRow}
                        editableRow={editableRow}
                        loadData={loadData}
                    />
                </>
            )}
        </>
    );
}
